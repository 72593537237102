.login-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;

  .login-input-field {
    margin: 20px 0;
  }

  .login-form-btn {
    margin: 20px 0 20px 0;
  }
}

.login-err-msg {
  color: red;
  margin-top: 20px;
  text-align: center;
}
