.sidebar {
  width: 268px;
}

.router-ctn {
  margin-top: 20px;
}

.ds-view-ctn {
  .avatar-icon {
    height: 20px;
    width: 20px;
  }

  .search-bar {
    width: 276px;
    height: 44px;
    padding: 48px 26px 18px 26px;

    position: fixed;
    z-index: 100;
    background-color: #ffffff;

    .searchbar-input {
      border-radius: 6px;
    }
  }

  .MuiList-root {
    padding-left: 26px;
    .MuiListSubheader-gutters {
      padding-left: 0;
      padding-bottom: 26px;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height */

      letter-spacing: 0.01em;
      color: #525151;
    }

    .MuiListItem-root {
      padding-left: 17px;
      padding-bottom: 4px;
      padding-top: 4px;

      .MuiListItemIcon-root {
        min-width: 40px;
      }

      .MuiListItemText-primary {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        /* identical to box height */

        letter-spacing: 0.01em;

        color: #525151;
      }
    }

    .nav-link {
      text-decoration: none;
    }
  }
}
