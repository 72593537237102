.sp-landing-page-ctn {
  padding: 24px 14px 60px 14px;

  .odd-selection {
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    width: 54px;
    padding: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .add-banner {
    margin: 0;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .sp-landing-page-ctn {
    padding-left: 14px;
    padding-top: 18px;
  }
}
