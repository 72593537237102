.table-ctn {
  padding: 1rem;
  .table {
    min-width: 650px;

    .MuiTableCell-head {
      font-weight: 600;
    }
  }
}
