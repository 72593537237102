.drawer-ctn {
  .MuiDrawer-paper {
    background-color: var(--ion-color-dark, #222428);
  }

  .toggle-btn {
    color: rgb(255, 255, 255);
  }

  .divider {
    background-color: rgb(255, 255, 255);
  }

  .drawer-header {
    padding: 0px 8px;
  }
}
