.nav-list {
  padding: 0 !important;

  .nav-link {
    text-decoration: none;

    .list-item {
      --background: var(--ion-color-dark, #222428);
      --color: #ffff;

      .nav-label {
        padding-left: 6px;
      }
    }
  }

  .active {
    .list-item {
      --background: #6b6c6c;
    }
  }

  ion-icon {
    color: #ffff;
  }
}
