.ds-view-ctn {
  .ion-toolbar {
    --min-height: 81px;
  }

  .header-refresh-icon {
    color: #fff;
  }

  .icon-title {
    padding: 0;
    margin-left: 48px;
    max-width: 180px;
    height: 33px;
    left: 30px;
    top: 28px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    /* identical to box height */

    letter-spacing: 0.02em;
    //color: #525151;
  }

  .nav-btns {
    font-family: Roboto;
    font-style: normal;

    .nav-link {
      margin-right: 50px;
      //opacity: 0.4;
    }

    .home-tab {
      width: 50px;
      height: 21px;
      left: 228px;
      top: 33px;

      // font-family: Roboto;
      // font-style: normal;
      // font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-transform: initial;
      letter-spacing: 0.02em;

      color: #525151;
    }

    .live-games-tab {
      width: 155px;
      height: 21px;
      left: 434px;
      top: 33px;

      // font-family: Roboto;
      // font-style: normal;
      // font-weight: normal;
      font-size: 18px;
      line-height: 21px;

      letter-spacing: 0.02em;
      text-transform: initial;
      color: #525151;
    }

    .sport-book-tab {
      width: 55px;
      height: 21px;
      left: 328px;
      top: 33px;

      // font-family: Roboto;
      // font-style: normal;
      // font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-transform: initial;
      letter-spacing: 0.02em;

      color: #525151;
    }
    .active {
      opacity: 1;
      border-bottom: 2px solid;
    }

    .active:after {
      margin-bottom: 50px;
    }
  }

  .login-btn {
    margin-right: 10px;
    text-transform: initial;
    width: 120px;
    height: 44px;
    left: 1142px;
    top: 19px;

    //opacity: 0.4;
    border: 1px solid rgba(0, 0, 0, 0.45);
    box-sizing: border-box;
    box-shadow: 0px 4px 40px rgba(225, 212, 252, 0.25);
    border-radius: 5px;

    // font-family: Roboto;
    // font-style: normal;
    // font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #525151;
  }

  .signup-btn {
    margin-right: 48px;
    width: 120px;
    height: 44px;
    left: 1272px;
    top: 19px;

    color: #ffffff;
    font-size: 18px;
    line-height: 21px;
    background: #525151;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    box-shadow: 0px 4px 40px rgba(225, 212, 252, 0.25);
    border-radius: 5px;
    text-transform: initial;
  }

  .log-out-btn {
    ion-icon {
      font-size: 34px;
    }
  }
}
